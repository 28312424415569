<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <!-- primary section -->
    <div v-else>
      <section class="for-homeowners">
        <b-container>
          <b-row>
            <b-col cols="12" lg="7">
              <h1 v-html="$prismic.asHtml(this.primary_title)" class="for-homeowners__title"></h1>
              <p v-html="$prismic.asHtml(this.primary_text_1)" class="main-text main-text--semi"></p>
              <a :href="this.primary_button_url" class="btn main-button for-homeowners__button">{{ $prismic.richTextAsPlain(this.primary_button_text) }}</a>
            </b-col>
            <b-col cols="12" lg="5">
              <img v-lazy="this.primary_image.url" class="for-homeowners__image img-fluid" :alt="this.primary_image.alt" />
            </b-col>
            <b-col cols="12">
              <p v-html="$prismic.asHtml(this.primary_text_2)" class="main-text mt-5"></p>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- features section -->
      <section class="features">
        <b-container>
          <b-row>
            <b-col cols="12">
              <h2 v-html="$prismic.asHtml(this.features_title)" class="features__title"></h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div v-if="this.features_elements.length > 0" class="features__box">
                <div v-for="(item, index) in this.features_elements" :key="`${index}`" class="features__element">
                  <b-row>
                    <b-col cols="12" md="3">
                      <img v-lazy="item.features_icon.url" class="features__icon img-fluid" :alt="item.features_icon.alt" />
                    </b-col>
                    <b-col cols="12" md="9">
                      <h3 v-html="$prismic.asHtml(item.features_subtitle)" class="features__subtitle"></h3>
                      <div  class="features__text" v-html="$prismic.asHtml(item.features_text)"></div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="features__iframe">
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  :src="this.features_video_url.url"
                  allowfullscreen
                ></b-embed>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- benefits section -->
      <section class="benefits">
        <b-container>
          <b-row>
            <b-col cols="12">
              <h2 v-html="$prismic.asHtml(this.benefits_title)" class="benefits__title"></h2>
            </b-col>
          </b-row>
            <div class="benefits__box">
              <b-row>
                <b-col cols="12" sm="3">
                  <img v-lazy="this.benefits_image.url" class="img-fluid benefits__profile" :alt="this.benefits_image.alt" />
                </b-col>
                <b-col cols="12" sm="9">
                  <div class="main-text" v-html="$prismic.asHtml(this.benefits_text)"></div>
                </b-col>
              </b-row>
          </div>
        </b-container>
      </section>
    </div>
    <!-- aside section -->
    <AsideComponent />
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'ForHomeownersPage',
  components: {
    Spinner,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      primary_title: null,
      primary_text_1: null,
      primary_button_text: null,
      primary_button_url: null,
      primary_image: null,
      primary_text_2: null,
      features_title: null,
      features_elements: [],
      features_video_url: null,
      benefits_title: null,
      benefits_image: null,
      benefits_text: null,
      cmsContentPending: false
    }
  },
  methods: {
    async getForHomeownersContent () {
      await this.$prismic.client.getSingle('forhomeownerspage')
        .then((document) => {
          // primary/hero section
          this.primary_title = document.data.primary_title
          this.primary_text_1 = document.data.primary_text_1
          this.primary_button_text = document.data.primary_button_text
          this.primary_button_url = document.data.primary_button_url.url
          this.primary_image = document.data.primary_image
          this.primary_text_2 = document.data.primary_text_2
          this.twitter_url = document.data.twitter_url
          // features section
          this.features_title = document.data.features_title
          this.features_elements = document.data.features_elements
          this.features_video_url = document.data.features_video_url
          // benefits section
          this.benefits_title = document.data.benefits_title
          this.benefits_image = document.data.benefits_image
          this.benefits_text = document.data.benefits_text
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getForHomeownersContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "./ForHomeownersPage.scss";
</style>
